/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@import "~bulma/sass/form/_all";
.control.has-icons-left {
  .icon {
    .material-icons {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, calc(2px - 50%));
      font-size: 18px;
    }
  }
}

.field.is-horizontal {
  .input,
  .textarea {
    &:not([readonly]) {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:read-only {
      border: none !important;
      padding-left: 0 !important;
      cursor: normal !important;
      opacity: 0.7;
    }
  }
  select {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.file-icon {
  i {
    font-size: 0.8rem;
  }
}
