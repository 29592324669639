/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@import './insight-gui-theme/sass/index.scss';
@import './custom/loading.scss';
@import './custom/terminal.scss';

@import '~bulma/sass/components/modal';
@import '~bulma/sass/components/pagination';
@import '~bulma/sass/components/tabs';

$modal-z-index: 10000001;

.is-icononly {
  i {
    margin-right: 0px !important;
  }
}
.is-hoverable {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
.table {
  tr.is-selected {
    //background: rgba(215, 44, 44, 0.05);
    color: #363636;
  }

  .table-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0.75rem;
    border: 1px solid #dbdbdb;
    border-width: 0;
    text-align: left;
  }
}

.input:hover,
.input.is-hovered,
.textarea:hover,
.textarea.is-hovered .input:hover,
.input.is-hovered,
.textarea:hover,
.textarea.is-hovered,
.input:focus,
.input.is-focused,
.input:active,
.input.is-active,
.textarea:focus,
.textarea.is-focused,
.textarea:active,
.textarea.is-active {
  border-color: rgba(0, 0, 0, 0.1);
}

.is-search-icon {
  transform: translate(6px, 6px);
}

.notification-container .message {
  background: transparent;
}

.modal-close-btn {
  cursor: pointer;
}

.field-body {
  flex-grow: 3;
}

.sidebar nav ul li {
  line-height: 2.5rem;
}

.sidebar nav ul li li.active:before {
  left: -72px;
}

.sidebar nav ul li ul {
  display: block;
}

.table tr.is-selected td,
.table tr.is-selected th {
  background: #eee;
}

.is-hoverable tr td {
  cursor: pointer;
}

.table.is-transparent {
  background: transparent !important;
}

.button {
  outline: none !important;
  box-shadow: none !important;
}

.select select {
  border-color: #eeeeee;
}

.is-inline-form {
  margin-right: 20px;
}

.modal-content,
.modal-card {
  width: auto;
  max-width: 1024px;
  min-width: 640px;
}

.is-narrow-th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

@media (max-width: 1600px) {
  .is-narrow-td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
  .is-small-table-button {
    display: block;
    text-align: left;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.is-label-small {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin-right: 5px;
  transform: translateY(12px);
}
.is-narrow-input {
  width: 9rem;
}

.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 100;
}
.sidebar {
  z-index: 99999;
}
header.page-header {
  z-index: 10000000;
}
.modal {
  z-index: $modal-z-index;

  .array-add {
    margin: 0;
  }

  .array-item {
    .button {
      margin: 0;
    }
  }
}
p.terminal {
  color: white;
  font: 1.3rem Inconsolata, monospace;
  background-color: black;
  padding: 20px;
  font-size: 12px;
}

.is-pointer {
  cursor: pointer;
  margin-left: 3px;
}

.is-form-spacer {
  padding-top: 1rem;
}

.is-form-spacer button {
  &:first-of-type {
    margin-left: 0px;
  }
}

.notification-container {
  z-index: 10000010 !important;
}
.field-body {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
}

.sidebar {
  background: linear-gradient(
    to bottom,
    #161632 60%,
    #ff2837 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  &:before {
    background: linear-gradient(
      to bottom,
      #161632 60%,
      #ff2837 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

input[type='checkbox'],
input[type='radio'] {
  transform: translateY(10px);
}

td {
  .fa.fa-check {
    opacity: 0.5;
  }
  .fa.fa-times {
    opacity: 0.5;
  }
}

.filterBox {
  position: absolute;
  min-height: 100vh;
  height: auto;
  ul {
    li {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
}

.is-details-icon {
  color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
}

.is-fullheight {
  height: 100%;
}

.button {
  &.is-single-button {
    margin-left: 0px;
  }
}

.is-under-title {
  padding-top: 0.5rem;
}

.column {
  .box {
    margin-bottom: 1.5rem;
  }
}

.is-nowrap {
  white-space: nowrap;
}

.report-pdf-iframe {
  width: 960px;
  height: 80vh;
}

.tag + .tag {
  margin-left: 5px;
}

h1 .tag {
  margin-left: 10px;
  margin-bottom: 5px;
}

.is-input-like {
  padding: 0.4rem 0;
}

.is-desc-label {
  font-weight: normal;
  color: #363636;
  font-style: italic;
}

.page-header-user {
  font-size: 0.85rem;
  cursor: pointer;
}

.is-filter {
  cursor: pointer;
}
.is-active-filter {
  font-weight: bold !important;
  cursor: pointer;
  position: relative;
  padding-right: 30px !important;
}

.is-filter-icon {
  color: #ff2837;
  font-size: 20px;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(calc(-50% - 4px));
}

.is-nav-title + nav {
  margin-left: 5px;
}

.machine-type {
  i {
    display: inline-block;
    margin-right: 5px;
  }
}

.page-header-title {
  h3::first-letter {
    text-transform: uppercase;
  }
}

.array-item {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
  .form-group {
    width: 100%;
    margin-left: 0.75rem;
    display: flex;

    .field {
      padding-right: 10px;
      &:last-of-type {
        padding-right: 0px;
      }
      &:nth-of-type(2) {
        flex-grow: 4;
      }
    }
    .is-horizontal {
      display: block;

      label {
        text-align: left;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .field-body {
      flex-grow: 8;
    }
  }
  & + button {
    margin-top: 1rem !important;
  }
  .is-error {
    input,
    select {
      border-color: red !important;
    }
  }
  .is-additional {
    display: none !important;
    visibility: collapse;
  }
}

.control {
  .button {
    &.is-icononly {
      margin-top: auto;
      height: 36px;
      width: 36px;
      background-color: rgba(22, 22, 50, 0.02);
      border: solid 1px rgba(22, 22, 50, 0.02);
      color: #696969;
    }

    &.is-input-icon {
      margin-top: 1.5rem;
    }
  }

  .edit-button {
    padding: 5px;
    cursor: pointer;
  }
}

.button.is-danger:focus,
.button.is-danger.is-focused {
  color: inherit !important;
}

.pagination-list {
  button {
    cursor: pointer;
  }
}

.modal-card-body {
  .field .is-horizontal {
    margin-bottom: 0.75em !important;
  }
}

.level-item {
  .button {
    height: 36px;
  }
}
.page-header-logo {
  cursor: pointer;
}

.hookOutput {
  margin-bottom: 1rem;
  text-align: left;
  ul {
    margin-bottom: 1rem;
  }
  .message-body {
    font-size: 12px;
  }
  &:not(:last-of-type) {
    border-bottom: 1px dashed #dbdbdb;
    padding-bottom: 1rem;
  }
}

.report-preview {
  border: 0px;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  min-height: 100px;
  height: auto;
  resize: none;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 1rem;
}

.cron-input {
  .input {
    width: 250px;
  }
  .info {
    height: 30px;
    padding-left: 10px;
    color: #999;
    line-height: 35px;
    font-style: italic;
  }
}

.button.high-order {
  background: #2e2e47 !important;
  color: white !important;

  i {
    color: white;
  }

  &:before {
    background: rgba(0, 0, 0, 0.5);
  }
}

.icon.is-danger {
  color: #ff3860;
  margin-right: 10px;
  font-size: 1.2em;
}

.column {
  .icon {
    margin-top: 5px;
  }
}

.pipeline-container {
  display: flex;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  .pipeline-step {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    margin: 0 3px;
    padding: 1.5rem;
    min-width: 180px;
    transition: background-color 0.2s ease;
    position: relative;
    margin-right: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 0.3rem;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    cursor: pointer;
    div {
      margin-top: 5px;
      button {
        margin-left: 0px;
      }
    }
    &:not(:last-of-type) {
      &:after {
        content: '➡';
        display: block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 0px;
        top: 50%;
        font-size: 1.5rem;
        transform: translate(calc(100% + 0.1rem), calc(-50%));
      }
    }
    &:hover {
      background: #fff;
    }
    span {
      color: #fff;
      font-weight: normal;
      &.is-warning {
        background-color: #ffdd57;
        color: rgba(0, 0, 0, 0.7);
        &:after {
          border-left: 17px solid #ffdd57;
        }
      }

      &.is-success {
        background-color: #23d160;

        &:after {
          border-left: 17px solid #23d160;
        }
      }

      &.is-info {
        background-color: #209cee;

        &:after {
          border-left: 17px solid #209cee;
        }
      }

      &.is-danger {
        background-color: #ff3860;

        &:after {
          border-left: 17px solid #ff3860;
        }
      }
    }
    /*
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 26px solid transparent;
      border-bottom: 17px solid transparent;
      z-index: 2;
      transition: border-color 0.2s ease;
    }

    &:before {
      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0;
    }

    &:first-child:before {
      border: none;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }*/
  }
}
.pipelineFormPlan {
  display: block;
  width: 100%;
  padding: 1rem 4rem;
  border-radius: 0.3rem;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  position: relative;
  i {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  background: rgba(0, 0, 0, 0.01);
  &:hover {
    background: rgba(0, 0, 0, 0);
  }
  &:not(:last-of-type) {
    margin-bottom: 3rem;
    &:after {
      display: block;
      content: '⬇';
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, 110%);
    }
  }
  &:last-of-type {
    margin-bottom: 1rem;
  }
  &:last-of-type + button {
    margin-left: 0px !important;
  }
}

.modal-card-regular {
  width: calc(100vw - 2rem) !important;
  max-width: 860px !important;
}

.modal-card-wide {
  width: calc(100vw - 2rem) !important;
  max-width: 100vw !important;
}

.button {
  a {
    color: #696969;
  }
}

.button.is-action {
  height: 45px !important;
  padding: 0 20px 0 10px !important;
  font-size: 0.7rem !important;

  i {
    margin-right: 1.4rem !important;
  }

  &:disabled {
    opacity: 0.4 !important;
    color: #363636 !important;
  }

  .loader {
    width: 20px;
    height: 45px;
    margin-right: 1.4rem;
  }
}

.cron-select {
  display: flex;
  justify-content: space-between;

  .cron-select-label {
    font-size: 1.3rem;
    padding-right: 0.5rem;
  }
}

.customer-menu {
  font-size: 2rem;
  margin-bottom: 1rem;

  span {
    cursor: pointer;
    &:hover {
      color: #c4c4c4;
    }
  }
}

.hook {
  position: relative;
  .tooltip {
    position: absolute;
    display: none;
    left: 0px;
    top: 0px;
    transform: translate(0%, -100%);
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 0.5rem;
    cursor: default;
  }
  &:hover {
    .tooltip {
      display: block;
    }
  }
}

span + .level {
  margin-top: 2rem;
}

.box-buttons {
  margin: 1.5rem 0;
  .button:first-child {
    margin-left: 0px !important;
  }
}

.button.is-success {
  .loader {
    height: 10px;
  }
}

.has-cursor-pointer {
  cursor: pointer;
}

.displayFlex {
  display: flex;
  align-items: center;
  justify-items: center;
}

.tippy-popper {
  z-index: $modal-z-index + 1 !important;
}

.paragraph {
  margin-bottom: 1rem;
}
