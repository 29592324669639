/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

.filter-wrapper {
  width: 244px;
  position: fixed;
  left: 72px;
  top: 0;
  height: 100vh;
  background: white;
  padding-top: 80px;
  z-index: 1;

  .hover-trigger {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 40px;
      right: -20px;

      &:hover {
        &~.filter-options-window {
          display: block;
        }
      }
    }

    &:hover {
      &~.filter-options-window {
        display: block;
      }
    }
  }

  .filter-options-window {
    display: none;
    padding: 1.5rem;
    width: 244px;
    position: absolute;
    z-index: 3;
    border-left: 1px solid #e0e0e0;
    left: 244px;
    top: 0;
    height: 100vh;
    background: white;
    padding-top: 80px;
    color: $blue;
    overflow-y: scroll;

    &:hover {
      display: block;
    }
  }

  .filterBox {
    width: 244px;
    height: 100vh;
    color: $blue;
    overflow-y: scroll;

    .title {
      color: $blue;

      .reset {
        font-size: 0.8em;
        transform: translateY(3px);
      }
    }
  }

}

.filter {
  position: static;
  .title {
    margin-bottom: 5px;
    color: $blue;
    i {
      font-size: 12px;
      margin-right: 2px;
      transform: translateY(2px);
    }
  }

  .dropdown {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
    }
  }

  .button {
    width: 100%;
    margin: 0;
    font-weight: normal;
    background-color: #ffffff !important;
    border: solid 1px #dfe6ee !important;
    height: 40px;
    align-items: flex-start;
    justify-content: space-between;
    text-transform: none;
    font-size: rem-calc(14);

    strong {
      font-family: "Gotham";
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;

      font-family: "Gotham Book";
    }
  }

  &.pristine {
    .button {
      color: #b0bac5 !important;
    }
  }

  margin-bottom: 2rem;
}
