/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

.terminal-container {
  background: #000;
  font-family: courier new;
  padding: 0.6rem;
  max-width: 984px;

  .terminal-error {
    color: #ff2837;
    font-weight: bold;
  }

  .terminal-content {
    font-weight: bold;
    resize: both;
    font-size: 1rem;
    background: #000;
    min-height: 500px;
    width: 100%;
    color: #fff;
    outline: none;
    border: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
