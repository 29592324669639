/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@import "../~bulma/sass/elements/button";

.button {
  font-size: 0.625rem;
  font-family: "Gotham";
  background-color: rgba(22, 22, 50, 0.02);
  border: solid 1px rgba(22, 22, 50, 0.02);
  text-transform: uppercase;
  &.is-white {
    background: #ffffff;
    border: solid 1px rgba(22, 22, 50, 0.1);
  }

  &:hover {
    border: solid 1px rgba(22, 22, 50, 0.02);

    background: #fff;
  }

  height: 30px;
  color: #696969;
  margin-left: 8px;
  i {
    margin-right: 5px;
    &.right {
      margin-left: 1rem;
      margin-right: 0;
      font-size: 20px;
    }
  }
  &.has-besel {
    &:before {
      position: absolute;
      width: 40px;
      left: 0;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.015);
      content: "";
    }
    &:after {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }
  &.is-action {
    height: 45px;
    padding: 0 20px 0 10px;
    background: #f5f5f5 !important;
    font-size: 0.7rem;
    color: #363636 !important;
    i {
      font-size: 2em;
      margin-right: 1.4rem;
      transform: translateY(3px);
      color: #363636;
    }
    &:hover {
      opacity: 0.9;
    }
    * {
    }
  }
}

.material-button {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}
