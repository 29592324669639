/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

header.page-header {
  position: fixed;
  width: 100%;
  height: rem-calc(60);
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  z-index: $layer-screen-attached;
  padding-right: $paddingContainer;
  .page-header-logo {
    display: block;
    float: left;
    width: rem-calc(72);
    height: rem-calc(60);
    position: relative;
    svg, img {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .page-header-title {
    display: block;
    font-size: rem-calc(20);
    color: $blue;
    line-height: rem-calc(18);
    padding-top: rem-calc(10);
    padding-left: rem-calc(7);
    float: left;
    span {
      font-size: rem-calc(10);
      display: block;
    }
  }
  .page-header-user {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem-calc(60);
    h3 {
      color: $blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      line-height: rem-calc(14);
      font-size: rem-calc(12);
      margin-right: 0.5rem;
      height: 100%;
      letter-spacing: rem-calc(0.5);
      font-family: $family-bold;
      span {
        font-size: rem-calc(10);
        font-family: $family-sans-serif;
        letter-spacing: rem-calc(0.4);
      }
    }
  }
}
