/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    color: $blue;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 0.8em);
      left: calc(50% - 0.8em);
      width: 1.6em;
      height: 1.6em;
      border-width: 0.25em;
    }
  }

  &.input-loader {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    margin: 4px;
  }
}

.loader {
  width: 50px;
  height: 50px;
}

.input-loader-wrapper {
  position: relative;
}
