/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@import "~bulma/sass/elements/table";
.table {
  a {
    color: inherit;
  }
  font-size: rem-calc(14);

  th {
    color: inherit;
    font-weight: normal;
    font-size: rem-calc(14);
  }
  letter-spacing: rem-calc(0.5);
  .button {
    font-size: rem-calc(10);
    font-family: $family-bold;
    background-color: rgba(22, 22, 50, 0.02);
    border: solid 1px rgba(22, 22, 50, 0.02);
    padding: 8px 15px 8px 10px;
    height: 30px;
    color: #696969;
    i {
      margin-right: 5px;
    }
  }
  th,
  td {
    vertical-align: middle;
    padding: 0.75rem;
  }
  &.condensed {
    font-size: rem-calc(11);
    th {
      font-size: rem-calc(10);
    }
  }
  .no-break {
    white-space: nowrap;
  }
}
