/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

* {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

.main {
  float: right;
  width: calc(100% - 72px);
  padding: 92px 2rem 2rem calc(260px + 2rem);

  &.wide {
    padding: 92px 2rem 2rem 2rem;
  }
}

canvas + table {
  margin-top: 4rem;
}

nav + canvas {
  margin-top: 2rem;
}

.chat-item {
  display: flex;

  .chat-desc {
    flex: 0 0 200px;
    h5 {
      margin: 0;
    }
  }

  article {
    flex: auto;

    &.is-odd {
      background: rgba(22, 22, 50, 0.05);
      .message-body {
        border-color: rgb(22, 22, 50);
      }
    }
    .attach {
      font-size: 0.8rem !important;
      text-decoration: none !important;

      i {
        display: inline-block;
        width: 20px;
        font-size: 18px;
        transform: translateY(4px);
      }
    }

    h4 {
      font-weight: bold;
      font-size: 0.8rem !important;
    }
  }

  margin-bottom: 2rem;
}

.details-list {

  li + li {
    margin-top: 1.45rem;
  }
}
