/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

.sidebar {
  max-width: rem-calc(312);
  width: rem-calc(72);
  padding-top: rem-calc(70);
  background: $blue;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  box-shadow: $box-shadow;
  position: fixed;
  left: 0;
  top: 0;
  transition: width 0.1s ease-out;
  z-index: $layer-blur-handler;

  &:before {
    display: block;
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    background: $blue;
    width: rem-calc(72);
    height: 100%;
  }

  &:hover,
  &.wide {
    width: rem-calc(320);
    box-shadow: 0 0 3px 0 rgba(20, 20, 55, 0.2);
  }

  .image-icons {
    img {
      display: inline;
      width: rem-calc(30);
      height: auto;
      transform: translateY(7px);
    }
    &.azure {
      img {
        transform: translateY(8px);
      }
    }
  }

  nav {

    ul {
      width: rem-calc(312);

      li {
        text-indent: rem-calc(10);
        min-height: rem-calc(45);
        line-height: rem-calc(51);
        font-family: $family-bold;
        letter-spacing: rem-calc(0.2);
        font-size: rem-calc(14);
        padding-top: rem-calc(4);
        position: relative;
        padding-left: rem-calc(72);

        i {
          color: #fff;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(calc(-43px - 50%), calc(-3px - 50%));
          opacity: 0.5;
        }

        &.active {
          background: rgba(255, 255, 255, 0.1);

          &:before {
            position: absolute;
            content: '';
            width: 72px;
            height: 100%;
            background: rgba(255, 255, 255, 0.1);
            left: 0;
            top: 0;
          }
        }

        a {
          color: #fff;
          position: relative;
          display: block;
        }

        ul {
          padding-left: 0;
          width: auto;
          display: none;

          li {
            font-family: $family-sans-serif;
            padding-left: 0;
            a {
              color: #999999;
            }
            &:hover,
            &.active {
              a {
                color: #fff;
              }
            }
          }
        }

        &.active,
        &:hover {

          ul {
            display: block;
          }

          i {
            opacity: 1;
          }

          li {

            i {
              opacity: 0.5;
            }

            &.active,
            &:hover {

              i {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
