/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@import "~bulma/sass/components/dropdown";
.dropdown-menu {
  min-width: 16rem;
  background: none;
  border: none;
  padding: 0;

  .dropdown-content {
    letter-spacing: normal;
    box-shadow: 0 2px 6px 0 #b0bac5;
    border-radius: 6px;

    .dropdown-scrollable {
      max-height: 20rem;
      overflow-y: auto;
    }
    .control {
      margin-left: 12px;

      input {
        border: none !important;
        outline: none !important;
        font-size: rem-calc(12);
        box-shadow: none !important;
      }
    }
  }
}

a.dropdown-item {
  padding: 0.5rem 1em;
  font-size: 12px;
  color: $blue;

  &.is-active {
    background: transparent;
    font-family: $family-bold;
    color: $blue;
  }
  &:hover {
    background: #f4f7fa;
  }
}
