/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

@font-face {
  font-family: "Gotham";
  src: url("./Gotham-Bold.woff2") format("woff2"),
    url("./Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src: url("./Gotham-Book.woff2") format("woff2"),
    url("./Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
